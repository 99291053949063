{
  "en": {
    "Basic Information": "Basic Information",
    "Travel Agency Booking Number": "Travel Agency Booking Number",
    "Anshin Reservation ID": "Anshin Reservation ID",
    "Check Out Date": "Check Out Date",
    "Travel Agency Booking Date": "Travel Agency Booking Date",
    "Nights": "Nights",
    "Travel Agency Booking Time": "Travel Agency Booking Time",
    "Package Plan Name": "Package Plan Name",
    "Guest Or Group Name Single Byte": "Guest Or Group Name Single Byte",
    "Package Plan Code": "Package Plan Code",
    "Guest Or Group Name Double Byte": "Guest Or Group Name Double Byte",
    "Other Service Information": "Other Service Information",
    "Guest Or Group Name Kanji Name": "Guest Or Group Name Kanji Name",
    "Original Message": "Original Message",
    "Check In Date": "Check In Date",
    "Specific Meal Condition": "Specific Meal Condition",
    "Check In Time": "Check In Time",
    "Check In Status": "Check In Status",
    "Check Out Status": "Check Out Status",
    "Temporary Key Code": "Temporary Key Code",
    "Smartlock Key Code": "Smartlock Key Code",
    "Transaction Type": "Transaction Type",
    "Data From": "Data From",
    "System Date": "System Date",
    "Data Classification": "Data Classification",
    "System Time": "System Time",
    "Accommodation Information": "Accommodation Information",
    "Accommodation Name": "Accommodation Name",
    "Accommodation Code": "Accommodation Code",
    "Sales Office Information": "Sales Office Information",
    "Sales Office Company Code": "Sales Office Company Code",
    "Sales Office Code": "Sales Office Code",
    "Sales Office Company Name": "Sales Office Company Name",
    "Sales Office Person In Charge": "Sales Office Person In Charge",
    "Sales Office Name": "Sales Office Name",
    "Sales Office Phone Number": "Sales Office Phone Number",
    "Basic Rate Information": "Basic Rate Information",
    "Room Rate or Personal Rate": "Room Rate or Personal Rate",
    "Payment": "Payment",
    "Tax Service Fee": "Tax Service Fee",
    "Total Accommodation Charge": "Total Accommodation Charge",
    "Checked in": "Checked in",
    "Not started": "Not started",
    "Departure Date": "Departure Date",
    "OTA Name": "OTA Name",
    "OTA Reservation Number": "OTA Reservation Number",
    "Anshin Reservation Number": "Anshin Reservation Number",

    "Member": "Member",
    "User Name": "User Name",
    "Print Category": "Print Category",
    "User Kana": "User Kana",
    "Member Status": "Member Status",
    "User Tel": "User Tel",
    "User Givin Points": "User Givin Points",
    "User Mail Address": "User Mail Address",
    "Point Name": "Point Name",
    "User Zip": "User Zip",
    "User Use Point": "User Use Point",
    "User Addr": "User Addr",
    "Total Accommodation Declease Points": "Total Accommodation Declease Points",
    "User Corp": "User Corp",
    "Amount Claimed": "Amount Claimed",
    "Member Number": "Member Number",
    "Representative Person Age": "Representative Person Age",
    "Accompanying Person List": "Accompanying Person List",
    "Accompanying Person Name": "Accompanying Person Name",
    "Accompanying Person Age": "Accompanying Person Age",
    "Accompanying Person Kana Name": "Accompanying Person Kana Name",
    "Accompanying Person Sex Category": "Accompanying Person Sex Category",
    "Add Form List": "Add Form List",
    "Add Form Name": "Add Form Name",
    "Add Form Value": "Add Form Value",

    "Room Information": "Room Information",
    "Room Type Code": "Room Type Code",
    "Room Type Name": "Room Type Name",
    "Room Child Other Count": "Room Child Other Count",
    "Room Child Other Name": "Room Child Other Name",
    "Per Room Pax Count": "Total pax",
    "Number of children (total)": "Number of children (total)",
    "Room Child A 70 Count": "Room Child A 70 Count",
    "Room Child A 70 Name": "Room Child A 70 Name",
    "Room Pax Male Count": "Room Pax Male Count",
    "Room Child B 50 Count": "Room Child B 50 Count",
    "Room Child B 50 Name": "Room Child B 50 Name",
    "Room Pax Female Count": "Room Pax Female Count",
    "Room Child C 30 Count": "Room Child C 30 Count",
    "Room Child C 30 Name": "Room Child C 30 Name",
    "Room Child D None Count": "Room Child D None Count",
    "Room Child D None Name": "Room Child D None Name",
    "Room Child E Count": "Room Child E Count",
    "Room Child E Name": "Room Child E Name",
    "Room Child F Count": "Room Child F Count",
    "Room Child F Name": "Room Child F Name",
    "Room Option": "Room Option",
    "Option Date": "Option Date",
    "Option Per Category": "Option Per Category",
    "Option Name": "Option Name",
    "Option Count": "Option Count",
    "Option Code": "Option Code",
    "Option Rate": "Option Rate",
    "Option Category": "Option Category",
    "Total Option Rate": "Total Option Rate",
    "Guest Information": "Guest Information",
    "Guest Name Single Byte": "Guest Name Single Byte",
    "Guest Emergency Phone Number": "Guest Emergency Phone Number",
    "Guest Surname": "Guest Surname",
    "Guest Kanji Name": "Guest Kanji Name",
    "Guest Email": "Guest Email",
    "Guest State Province": "Guest State Province",
    "Guest Phone Number": "Guest Phone Number",
    "Guest Zip": "Guest Zip",
    "Room Rate Information": "Room Rate Information",
    "Room Date": "Room Date",
    "Room Child A 70 Rate Name": "Room Child A 70 Rate Name",
    "Per Pax Rate": "Per Pax Rate",
    "Room Child B50 Rate Name": "Room Child B50 Rate Name",
    "Room Child A70 Rate": "Room Child A70 Rate",
    "Room Child D Rate": "Room Child D Rate",
    "Room Child D Rate Name": "Room Child D Rate Name",
    "Room Child B50 Rate": "Room Child B50 Rate",
    "Room Child E Rate": "Room Child E Rate",
    "Room Child E Rate Name": "Room Child E Rate Name",
    "Room Child F Rate": "Room Child F Rate",
    "Room Child F Rate Name": "Room Child F Rate Name",
    "Room Child C Rate": "Room Child C Rate",
    "Room Child C Rate Name": "Room Child C Rate Name",
    "Total Per Room Rate": "Total Per Room Rate",

    "Length of Stay": "Length of Stay",
    "Total Pax": "Total Pax",
    "Adult": "Adult",
    "Child": "Child",
    "Infant": "Infant",
    "Charge": "Charge",
    "Total Accomodation Charge": "Total Accomodation Charge",
    "Point Used": "Point Used",
    "Guest Payment": "Guest Payment",
    "Payment Method": "Payment Method",
    "Travel Agency Name": "Travel Agency Name",
    "Resend Anshin": "Resend Anshin",
    "Latest guest email": "Latest guest email"
  },
  "ja": {
    "Basic Information": "基本宿泊情報",
    "Travel Agency Booking Number": "旅行会社予約(管理)番号",
    "Anshin Reservation ID": "あんしん予約ID",
    "Check Out Date": "チェックアウト日",
    "Travel Agency Booking Date": "旅行会社予約受付日",
    "Nights": "宿泊日数",
    "Travel Agency Booking Time": "旅行会社受付時間",
    "Package Plan Name": "企画(パッケージ)名",
    "Guest Or Group Name Single Byte": "団体名または代表者氏名(半角)",
    "Package Plan Code": "企画(パッケージ)コード",
    "Guest Or Group Name Double Byte": "団体名または代表者氏名よみがな(全角)",
    "Other Service Information": "一般情報",
    "Guest Or Group Name Kanji Name": "団体名または代表者氏名 漢字",
    "Original Message": "通知電文",
    "Check In Date": "チェックイン日",
    "Specific Meal Condition": "食事条件",
    "Check In Time": "チェックイン時間",
    "Check In Status": "チェックインステータス",
    "Check Out Status": "ステータスを確認する",
    "Temporary Key Code": "一時的なキーコード",
    "Smartlock Key Code": "Smartlockキーコード",
    "Transaction Type": "情報区分",
    "Data From": "送り元区分",
    "System Date": "システム日付",
    "Data Classification": "データ種別",
    "System Time": "システム時刻",
    "Accommodation Information": "宿泊施設側情報",
    "Accommodation Name": "宿泊施設名",
    "Accommodation Code": "宿泊施設コード",
    "Sales Office Information": "旅行会社情報",
    "Sales Office Company Code": "旅行会社コード",
    "Sales Office Code": "旅行会社営業所コード",
    "Sales Office Company Name": "旅行会社名",
    "Sales Office Person In Charge": "旅行会社営業所担当者名",
    "Sales Office Name": "旅行会社営業所名",
    "Sales Office Phone Number": "旅行会社営業所電話番号",
    "Basic Rate Information": "基本情報",
    "Room Rate or Personal Rate": "料金区分",
    "Payment": "支払い方法",
    "Tax Service Fee": "税サ区分",
    "Total Accommodation Charge": "合計宿泊料金(総額)",
    "Checked in": "チェックインした",
    "Not started": "始まっていない",
    "Departure Date": "出発日",
    "OTA Name": "予約元のサイト名",
    "OTA Reservation Number": "予約元の予約番号",
    "Anshin Reservation Number": "あんしん予約番号",

    "Member": "予約者・会員情報",
    "User Name": "予約者・会員名漢字",
    "Print Category": "予約者・案内書印字区分",
    "User Kana": "予約者・会員名カタカナ",
    "Member Status": "会員状態",
    "User Tel": "予約者・会員電話番号",
    "User Givin Points": "付与ポイント",
    "User Mail Address": "予約者・会員Email",
    "Point Name": "ポイント名称",
    "User Zip": "予約者・会員郵便番号",
    "User Use Point": "ポイント金額",
    "User Addr": "予約者・会員住所",
    "Total Accommodation Declease Points": "ポイント割引後の総額",
    "User Corp": "予約者・会員会社",
    "Amount Claimed": "宿泊者請求額",
    "Member Number": "予約者・会員番号",
    "Representative Person Age": "ツアープラン代表者年齢",
    "Accompanying Person List": "同行者情報",
    "Accompanying Person Name": "同行者氏名",
    "Accompanying Person Age": "同行者年齢",
    "Accompanying Person Kana Name": "同行者氏名(半角カナ)",
    "Accompanying Person Sex Category": "同行者性別区分",
    "Add Form List": "追加フォーム情報",
    "Add Form Name": "追加フォーム項目名",
    "Add Form Value": "追加フォーム入力値",

    "Room Information": "部屋情報",
    "Room Type Code": "部屋タイプコード",
    "Room Type Name": "部屋番号",
    "Room Child Other Count": "子供その他人数",
    "Room Child Other Name": "子供その他名称",
    "Per Room Pax Count": "利用人数",
    "Number of children (total)": "子供人数(合計)",
    "Room Pax Male Count": "大人人数(男性)",
    "Room Pax Female Count": "大人人数(女性)",
    "Room Child A 70 Count": "子供A人数",
    "Room Child A 70 Name": "子供A名称",
    "Room Child B 50 Count": "子供B人数",
    "Room Child B 50 Name": "子供B名称",
    "Room Child C 30 Count": "子供C人数",
    "Room Child C 30 Name": "子供C名称",
    "Room Child D None Count": "子供D人数",
    "Room Child D None Name": "子供D名称",
    "Room Child E Count": "子供E人数",
    "Room Child E Name": "子供E名称",
    "Room Child F Count": "子供F人数",
    "Room Child F Name": "子供F名称",
    "Room Option": "オプション情報",
    "Option Date": "オプション利用日",
    "Option Per Category": "オプション人員区分",
    "Option Name": "オプション名",
    "Option Count": "オプション数",
    "Option Code": "オプションコード",
    "Option Rate": "オプション料金",
    "Option Category": "オプション区分",
    "Total Option Rate": "オプション料金合計",
    "Guest Information": "お客様情報",
    "Guest Name Single Byte": "お客様氏名半角カタカナ英字(姓名)",
    "Guest Emergency Phone Number": "お客様緊急連絡先",
    "Guest Surname": "お客様氏名全角ふりがな(姓名)",
    "Guest Kanji Name": "お客様氏名全角漢字(姓名)",
    "Guest Email": "お客様Email",
    "Guest State Province": "お客様住所都道府県",
    "Guest Phone Number": "お客様電話番号",
    "Guest Zip": "お客様郵便番号",
    "Room Rate Information": "部屋料金情報",
    "Room Date": "利用年月日",
    "Room Child A70 Rate Name": "子供A名称",
    "Per Pax Rate": "大人単価（一人料金)",
    "Room Child B50 Rate Name": "子供B名称",
    "Room Child A70 Rate": "子供A単価（一人料金)",
    "Room Child D Rate": "子供D単価（一人料金)",
    "Room Child D Rate Name": "子供D名称",
    "Room Child B50 Rate": "子供B単価（一人料金)",
    "Room Child E Rate": "子供E単価（一人料金)",
    "Room Child E Rate Name": "子供E名称",
    "Room Child F Rate": "子供F単価（一人料金)",
    "Room Child F Rate Name": "子供F名称",
    "Room Child C Rate": "子供C単価（一人料金)",
    "Room Child C Rate Name": "子供C名称",
    "Total Per Room Rate": "1室あたり宿泊料金合計",

    "Length of Stay": "滞在期間",
    "Total Pax": "宿泊人数",
    "Adult": "大人",
    "Child": "子供",
    "Infant": "幼児",
    "Charge": "宿泊料",
    "Total Accomodation Charge": "宿泊料金",
    "Point Used": "ポイント利用",
    "Guest Payment": "ゲスト支払額",
    "Payment Method": "決済方法",
    "Travel Agency Name": "予約チャンネル",
    "Resend Anshin": "予約をあんしんへ再送する",
    "Latest guest email": "最終送信メール"
  }
}
